<template>
  <data-list
    ref="dataList"
    class="content md"
    :url="url"
    :query="query"
    entity-name="入库统计"
    hide-action
    show-row-num
    :columns="columns"
    :search-key-type="searchKeyType"
    :requestConfig="{ noTempleFilter: true }"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item label="交货人">
        <select-book type="user" v-model="form.delivered_by" @change="updateList"></select-book>
      </a-form-model-item>
      <!-- <a-form-model-item>
        <a-select
          style="width: 180px;"
          placeholder="选择商品类别查询"
          @change="updateList"
          :options="warehousingOptions"
          v-model="form.category"
          allowClear
        >
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item>
        <a-input v-model="form.goods_name" placeholder="输入商品名称/SKU查询" :maxLength="50" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item>
        <a-range-picker
          v-model="form.warehouse_date"
          :placeholder="['入库开始日期', '入库截止日期']"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          @change="updateList"
          class="range-picker"
          allowClear
        />
      </a-form-model-item>
      <a-form-model-item>
        <a-input v-model="form.notes" placeholder="输入备注查询" allowClear></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
import { warehousingOptions } from "../../common/asset/type";
import { getTemple } from "../../common/js/storage";
import { stringConvertTimestamp } from "../../common/js/tool";

export default {
  name: "WarehousingStatistics",
  data() {
    return {
      url: "/admin/asset-warehouse/statistics",
      searchKeyType: {
        delivered_by: 2,
        // category: 2,
        goods_name: 2,
        warehouse_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value?.length > 0) {
            res = `&start_date=${stringConvertTimestamp(value[0])}&end_date=${stringConvertTimestamp(value[1])}`;
          }
          return res;
        },
        notes: 2,
      },
      columns: [
        { title: "商品SKU", dataIndex: "sku" },
        { title: "商品名称", dataIndex: "name" },
        { title: "商品单位", dataIndex: "unit_name" },
        { title: "数量", dataIndex: "total_num" },
        { title: "平均价（元）", dataIndex: "average_price" },
        { title: "合计金额（元）", dataIndex: "total_price" },
      ],
      warehousingOptions,
    };
  },
  computed: {
    dataList() {
      return this.$refs.dataList;
    },
    query() {
      let temple = getTemple();
      return `&temple_id=${temple}`;
    },
  },
  created() {},
  methods: {
    updateList() {
      this.dataList.updateList();
    },
  },
};
</script>

<style lang="less" scoped></style>
